import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api/api.service';
import { CourseSectionI } from './interfaces/course-section.interface';
import { FilterCourseSectionsForCourseInAcademicYearI } from './interfaces/filter-course-sections-for-course-in-academic-year.interface';
import { AolCourseSection } from './interfaces/aol-report-course-section.interface';

@Injectable({
  providedIn: 'root',
})
export class CourseSectionsApiService {
  constructor(private readonly apiService: ApiService) {}

  findAllForCourseInAcademicYear(
    courseID: string,
    academicYearID: string,
    filterCourseSectionsForCourseInAcademicYear: FilterCourseSectionsForCourseInAcademicYearI
  ) {
    return this.apiService.get<CourseSectionI[]>(
      `course-sections/course/${courseID}/academic-year/${academicYearID}`,
      filterCourseSectionsForCourseInAcademicYear
    );
  }

  findOne(courseSectionID: string) {
    return this.apiService.get<CourseSectionI>(
      `course-sections/${courseSectionID}`
    );
  }

  withdrawStudent(courseSectionID: string, studentID: string) {
    return this.apiService.patch(
      `course-sections/${courseSectionID}/withdraw-student/${studentID}`
    );
  }

  aol(courseSectionID: string) {
    return this.apiService.get<AolCourseSection>(
      `course-sections/${courseSectionID}/aol/clo-plo`
    );
  }
}
